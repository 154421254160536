import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import { useNavigate } from "react-router";
import { signInUsingEmailPassword } from "../services/firebaseAuthService";
import { Link } from "react-router-dom";
import ProgressIndicator from "../components/ProgressIndicator";
import { sendEmailVerification } from "firebase/auth";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "./SignIn.css";

const SignIn = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [user, loadingAuthState] = useAuthState(auth);
  const [isLoading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = async (data) => {
    setLoading(true);
    setErrorMessage("");
    try {
      await signInUsingEmailPassword(data.email, data.password);
      // Check if email is verified
      const currentUser = auth.currentUser;
      if (currentUser && !currentUser.emailVerified) {
        
        console.log('user not verified - sending verification email');
        await sendEmailVerification(currentUser);
        alert("Your email is not verified. A new verification email has been sent. Please verify your email before logging in.");
        await auth.signOut();
        setLoading(false);
        return;
      }
      navigate("/signin");
      
    } catch (error) {
      setErrorMessage(error.message);
    }
    setLoading(false);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  useEffect(() => {
    document.title = "Sign In - Digital Visiting Card";
    if (!loadingAuthState && user) {
      navigate("/");
    }
  }, [user, loadingAuthState, navigate]);

  return (
    <>
      {isLoading ? (
        <ProgressIndicator type={2} />
      ) : (
        <div className="signin-background">
          <div className="signin-container">
            <h2 className="signin-title">Sign In</h2>
            <form className="signin-form" onSubmit={handleSubmit(onSubmit)} noValidate>
              <div className="input-group">
                <input
                  type="email"
                  placeholder="Email Address"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: "Enter a valid email",
                    },
                  })}
                />
                {errors.email && <span className="error-message">{errors.email.message}</span>}
              </div>
              <div className="input-group">
                <input
                  type={passwordVisible ? "text" : "password"}
                  placeholder="Password"
                  {...register("password", { required: "Password is required" })}
                />
                <span className="toggle-password" onClick={togglePasswordVisibility}>
                  {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                </span>
                {errors.password && <span className="error-message">{errors.password.message}</span>}
              </div>
              {errorMessage && <span className="error-message">{errorMessage}</span>}
              <Link to="/forgot-password" className="forgot-password">
                Forgot Password?
              </Link>
              <button type="submit" className="signin-button">
                Log In
              </button>
              <p className="signup-link">
                Don't have an account? <Link to="/signup">Sign Up</Link>
              </p>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default SignIn;
