import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import "./ForgotPassword.css";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from '../firebase';
import { useNavigate } from 'react-router';
import { resetPasswordUsingEmail } from '../services/firebaseAuthService';
import ProgressIndicator from '../components/ProgressIndicator';
import { Link } from 'react-router-dom';

const ForgotPassword = () => {
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [user, loadingAuthState] = useAuthState(auth);
    const [isLoading, setLoading] = useState(false);

    const onSubmit = async (data) => {        
        setLoading(true);
        await resetPasswordUsingEmail(data.email);
        setLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        document.title = "Forget Password - Digital Visiting Card";
        if (loadingAuthState) return;
        if (user) {
            return navigate("/");
        }
        setLoading(false);
    }, [user, loadingAuthState, navigate]);

    return (
        <>
            {isLoading ? (
                <ProgressIndicator type={2} />
            ) : (
                <div id="Forgot-Password">
                    <form noValidate className="Form" onSubmit={handleSubmit(onSubmit)}>
                        <h2 className="MT0">Forgot Password</h2>
                        <div className="Field">
                            <input 
                                type="email" 
                                placeholder="Enter e-mail*"
                                {...register("email", {
                                    required: true,
                                    pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
                                })}
                            />
                            {errors.email && errors.email.type === "required" && <span className="Error">Email can't be empty</span>}
                            {errors.email && errors.email.type === "pattern" && <span className="Error">Invalid email</span>}
                        </div>
                        <input className="Btn-Submit" type="submit" value="Continue" />
                        <Link className="Btn-Submit" to="/signin">Back to Sign In</Link>
                    </form>
                </div>
            )}
        </>
    );
};

export default ForgotPassword;
