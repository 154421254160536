import React, { useState } from "react";
import { createAffiliate } from "../services/affiliateService";
import "./AffiliateRegistration.css";

const AffiliateRegistration = () => {
  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    phone: "",
    address: "",
    account_number: "",
    ifsc: "",
    account_holder: "",
    bank_name: "",
    branch_name: "",
    terms: false,
  });
  const [referralLink, setReferralLink] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(""); // State for error message

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
    setError(""); 
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.terms) {
      alert("Please agree to the Terms & Conditions.");
      return;
    }

    setLoading(true);
    setError("");
    try {
      const affiliate = await createAffiliate(formData);
      const link = `${window.location.origin}/signup?ref=${affiliate.referralCode}`;
      setReferralLink(link);
      setShowModal(true); // Show modal when successful
    } catch (error) {
      setError(error.message); 
    }
    setLoading(false);
  };

  return (
    <div className="affiliate-container">
      <h2 className="affiliate-heading">Become an Affiliate</h2>
      <p className="affiliate-subheading">
        Fill in the form below to register as an affiliate and start earning with us.
      </p>

      {error && <div className="error-message">{error}</div>} {/* Error Message Display */}

      <form onSubmit={handleSubmit} className="affiliate-form">
        <div className="form-group">
          <label htmlFor="full_name">Full Name</label>
          <input
            type="text"
            id="full_name"
            name="full_name"
            placeholder="Full Name"
            value={formData.full_name}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">Email Address</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Email Address"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="phone">Phone Number</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            placeholder="Phone Number"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="address">Address</label>
          <textarea
            id="address"
            name="address"
            placeholder="Address"
            value={formData.address}
            onChange={handleChange}
            required
          ></textarea>
        </div>

        <h3 className="bank-heading">Bank Details</h3>

        <div className="form-group">
          <label htmlFor="account_number">Account Number</label>
          <input
            type="text"
            id="account_number"
            name="account_number"
            placeholder="Account Number"
            value={formData.account_number}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="ifsc">IFSC Code</label>
          <input
            type="text"
            id="ifsc"
            name="ifsc"
            placeholder="IFSC Code"
            value={formData.ifsc}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="account_holder">Account Holder Name</label>
          <input
            type="text"
            id="account_holder"
            name="account_holder"
            placeholder="Account Holder Name"
            value={formData.account_holder}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="bank_name">Bank Name</label>
          <input
            type="text"
            id="bank_name"
            name="bank_name"
            placeholder="Bank Name"
            value={formData.bank_name}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="branch_name">Branch Name</label>
          <input
            type="text"
            id="branch_name"
            name="branch_name"
            placeholder="Branch Name"
            value={formData.branch_name}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group terms-group">
          <input
            type="checkbox"
            id="terms"
            name="terms"
            checked={formData.terms}
            onChange={handleChange}
            required
          />
          <label htmlFor="terms">
            I agree to the{" "}
            <a
              href="https://dgtldigicard.com/affiliate-terms-and-conditions/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms &amp; Conditions
            </a>
          </label>
        </div>

        <button type="submit" className="submit-btn" disabled={loading}>
          {loading ? "Creating..." : "Create Affiliate"}
        </button>
      </form>

      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3 className="success-heading">Affiliate Registration Successful</h3>
            <p>Your registration has been received. Our team will contact you shortly for verification.</p>
            <p>In the meantime, you can start sharing your referral link:</p>
            <a href={referralLink} target="_blank" rel="noopener noreferrer" className="referral-link">
              {referralLink}
            </a>
            <button onClick={() => setShowModal(false)} className="close-btn">Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AffiliateRegistration;
