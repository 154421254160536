import React, { useState, useEffect } from 'react';
import { LayoutDashboard, Megaphone, Users, Mail, Link2, LogOut } from 'lucide-react';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

// Dummy page components – replace these with your actual pages.
import Home from './pages/Home';
import AdControl from './pages/AdControl';
import UserInfo from './pages/UserInfo';
import Mailer from './pages/Mailer';
import AffiliateApprovalPage from '../affiliate/AffiliateApprovalPage';
import AffiliateListPage from '../affiliate/AffiliateListPage';
import AffiliatePaymentPage from '../affiliate/AffiliatePaymentPage';

import './AdminDashboard.css'; // External CSS file for styling

const Sidebar = ({ activePage, setActivePage, onLogout }) => (
  <div className="sidebar">
    <h2 className="sidebar-header">Admin Panel</h2>
    <nav className="sidebar-nav">
      <button
        className={`sidebar-nav-item ${activePage === 'home' ? 'active' : ''}`}
        onClick={() => setActivePage('home')}
      >
        <LayoutDashboard className="icon" /> Home
      </button>
      <button
        className={`sidebar-nav-item ${activePage === 'ad-control' ? 'active' : ''}`}
        onClick={() => setActivePage('ad-control')}
      >
        <Megaphone className="icon" /> Ad Control
      </button>
      <button
        className={`sidebar-nav-item ${activePage === 'user-info' ? 'active' : ''}`}
        onClick={() => setActivePage('user-info')}
      >
        <Users className="icon" /> User Info
      </button>
      <button
        className={`sidebar-nav-item ${activePage === 'mailer' ? 'active' : ''}`}
        onClick={() => setActivePage('mailer')}
      >
        <Mail className="icon" /> Mailer Page
      </button>
      <button
        className={`sidebar-nav-item ${activePage === 'affiliate-approval' ? 'active' : ''}`}
        onClick={() => setActivePage('affiliate-approval')}
      >
        <Link2 className="icon" /> Affiliate Approval
      </button>
      <button
        className={`sidebar-nav-item ${activePage === 'affiliate-list' ? 'active' : ''}`}
        onClick={() => setActivePage('affiliate-list')}
      >
        <Link2 className="icon" /> Affiliate List
      </button>
      <button
        className={`sidebar-nav-item ${activePage === 'affiliate-payment' ? 'active' : ''}`}
        onClick={() => setActivePage('affiliate-payment')}
      >
        <Link2 className="icon" /> Affiliate Payment
      </button>
      <button
        className="sidebar-nav-item logout-btn"
        onClick={onLogout}
      >
        <LogOut className="icon" /> Logout
      </button>
    </nav>
  </div>
);

const AdminDashboard = () => {
  const [activePage, setActivePage] = useState('home');
  const [isAdmin, setIsAdmin] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          if (idTokenResult.claims.admin) {
            setIsAdmin(true);
          } else {
            setIsAdmin(false);
            navigate('/admin/login');
          }
        });
      } else {
        setIsAdmin(false);
        navigate('/admin/login');
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  // Handle logout using Firebase auth signOut and then navigate to /admin-login.
  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/admin/login');
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  if (isAdmin === null) {
    return <div>Loading...</div>; // Prevents flickering when checking admin status
  }

  // Conditionally render the page component based on activePage state.
  const renderContent = () => {
    switch (activePage) {
      case 'home':
        return <Home />;
      case 'ad-control':
        return <AdControl />;
      case 'user-info':
        return <UserInfo />;
      case 'mailer':
        return <Mailer />;
      case 'affiliate-approval':
        return <AffiliateApprovalPage />;
      case 'affiliate-list':
        return <AffiliateListPage />;
      case 'affiliate-payment':
        return <AffiliatePaymentPage />;
      default:
        return <Home />;
    }
  };

  return (
    <div className="admin-dashboard-container">
      <Sidebar activePage={activePage} setActivePage={setActivePage} onLogout={handleLogout} />
      <div className="admin-dashboard-content">
        {renderContent()}
      </div>
    </div>
  );
};

export default AdminDashboard;
