import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import './SignUp.css';
import { useNavigate } from 'react-router';
import { signUpUsingEmailPassword } from '../services/firebaseAuthService';
import { getAuth, sendEmailVerification } from 'firebase/auth';
import { Link } from 'react-router-dom';
import ProgressIndicator from '../components/ProgressIndicator';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const SignUp = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [isLoading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const auth = getAuth();

  // Extract referral code from URL query parameter
  const searchParams = new URLSearchParams(window.location.search);
  const referralCode = searchParams.get('ref') || "";

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      // Create account using your Firebase service
      await signUpUsingEmailPassword({
        ...data,
        firstName: data.firstName.trim(),
        lastName: data.lastName.trim(),
        businessName: data.businessName.trim(),
        website: data.website.trim(),
        about: data.about.trim(),
        address: data.address.trim(),
        affiliateRef: referralCode,
      });
      
      // Send email verification
      const currentUser = auth.currentUser;
      if (currentUser) {
        await sendEmailVerification(currentUser);
        
        alert('A verification link has been sent to your email. Please verify your email before logging in.');
        await auth.signOut();
        navigate('/signin');
      }
      
    } catch (error) {
      console.error('Sign up error:', error);
      alert(error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    document.title = "Signup - Digital Visiting Card";
  }, []);

  return (
    <>
      {isLoading ? (
        <ProgressIndicator type={2} />
      ) : (
        <div id="Sign-Up">
          <form noValidate className="Form" onSubmit={handleSubmit(onSubmit)}>
            <h2 className="MT0">Create New Account</h2>
            
            {/* FIRST NAME */}
            <div className="Field">
              <input 
                type="text" 
                placeholder="First Name*"
                {...register("firstName", {
                  required: true,
                  pattern: /^[a-zA-Z ]{2,30}$/,
                  validate: (value) => value.trim().length !== 0
                })}
              />
              {errors.firstName && <span className="Error">Invalid first name</span>}
            </div>
            
            {/* LAST NAME */}
            <div className="Field">
              <input 
                type="text" 
                placeholder="Last Name*"
                {...register("lastName", {
                  required: true,
                  pattern: /^[a-zA-Z ]{2,30}$/,
                  validate: (value) => value.trim().length !== 0
                })}
              />
              {errors.lastName && <span className="Error">Invalid last name</span>}
            </div>
            
            {/* EMAIL */}
            <div className="Field">
              <input 
                type="email" 
                placeholder="E-mail*"
                {...register("email", {
                  required: true,
                  pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
                })}
              />
              {errors.email && <span className="Error">Invalid email</span>}
            </div>
            
            {/* MOBILE NUMBER */}
            <div className="Field">
              <input 
                type="text" 
                placeholder="Contact Number* (include country code)" 
                {...register("mobile", {
                  required: true,
                  pattern: /^[+]?[(]?[0-9]{1,4}[)]?[-\\s.]?[0-9]{1,4}[-\\s.]?[0-9]{1,4}[-\\s.]?[0-9]{1,6}$/
                })}
              />
              {errors.mobile && <span className="Error">Invalid contact number</span>}
            </div>
            
            {/* BUSINESS NAME */}
            <div className="Field">
              <input 
                type="text" 
                placeholder="Business Name*"
                {...register("businessName", {
                  required: true,
                  pattern: /^[a-zA-Z ]{2,30}$/
                })}
              />
              {errors.businessName && <span className="Error">Invalid business name</span>}
            </div>
            
            {/* ADDRESS */}
            <div className="Field">
              <input 
                type="text" 
                placeholder="Address*"
                {...register("address", { required: true })}
              />
              {errors.address && <span className="Error">Address is required</span>}
            </div>
            
            {/* WEBSITE URL */}
            <div className="Field">
              <input 
                type="text" 
                placeholder="Website*"
                {...register("website", {
                  required: true,
                  pattern: /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/
                })}
              />
              {errors.website && <span className="Error">Enter a valid URL</span>}
            </div>
            
            {/* ABOUT */}
            <div className="Field">
              <input 
                type="text" 
                placeholder="About*"
                {...register("about", { required: true })}
              />
              {errors.about && <span className="Error">This field is required</span>}
            </div>
            
            {/* PASSWORD */}
            <div className="Field">
              <div className="Input-Password">
                <input 
                  type={passwordVisible ? "text" : "password"} 
                  placeholder="Password*"
                  {...register("password", {
                    required: true,
                    minLength: 6,
                    validate: (value) => value.trim().length >= 6
                  })}
                />
                <span className="Visibility-Icon" onClick={togglePasswordVisibility}>
                  {passwordVisible ? <FaEyeSlash size="20" color="grey" /> : <FaEye size="20" color="grey" />}
                </span>
              </div>
              {errors.password && <span className="Error">Password must be at least 6 characters</span>}
            </div>
            
            {/* CONFIRM PASSWORD */}
            <div className="Field">
              <div className="Input-Password">
                <input 
                  type={passwordVisible ? "text" : "password"}
                  placeholder="Confirm Password*"
                  {...register("confirmPassword", {
                    validate: (value) => value === watch("password")
                  })}
                />
                <span className="Visibility-Icon" onClick={togglePasswordVisibility}>
                  {passwordVisible ? <FaEyeSlash size="20" color="grey" /> : <FaEye size="20" color="grey" />}
                </span>
              </div>
              {errors.confirmPassword && <span className="Error">Passwords do not match</span>}
            </div>
            
            <input className="Btn-Submit" type="submit" value="Create Account" />
            <p className="Login-Link">
              Already have an account?<br/><Link to="/signin">Log In</Link> now.
            </p>
          </form>
        </div>
      )}
    </>
  );
};

export default SignUp;
