import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth} from "../firebase";
import { useNavigate, Link } from "react-router-dom";
import { signInUsingEmailPassword } from "../services/firebaseAuthService";
import ProgressIndicator from "../components/ProgressIndicator";
import {  signOut } from "firebase/auth";

import { FaEye, FaEyeSlash } from "react-icons/fa";

const styles = {
  background: {
    background: "#f1f1f1",
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    background: "#ffffff",
    maxWidth: "500px",
    width: "100%",
    padding: "24px",
    borderRadius: "10px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    border: "1px solid #ddd",
    margin: "20px",
  },
  title: {
    textAlign: "center",
    fontSize: "24px",
    fontWeight: "bold",
    color: "#333",
    marginBottom: "10px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  inputGroup: {
    position: "relative",
    marginBottom: "16px",
  },
  input: {
    width: "100%",
    padding: "10px 12px",
    border: "1px solid #ccc",
    borderRadius: "6px",
    fontSize: "14px",
  },
  togglePassword: {
    position: "absolute",
    right: "12px",
    top: "50%",
    transform: "translateY(-50%)",
    cursor: "pointer",
    color: "#666",
  },
  errorMessage: {
    color: "#ff4d4f",
    fontSize: "12px",
    marginTop: "4px",
  },
  forgotPassword: {
    fontSize: "12px",
    textAlign: "right",
    color: "#2563eb",
    textDecoration: "underline",
    marginBottom: "16px",
  },
  button: {
    background: "#2563eb",
    color: "white",
    padding: "10px",
    border: "none",
    borderRadius: "6px",
    fontSize: "16px",
    cursor: "pointer",
    marginBottom: "12px",
  },
  signupLink: {
    textAlign: "center",
    fontSize: "14px",
    color: "#333",
  },
  signupLinkAnchor: {
    color: "#2563eb",
    textDecoration: "underline",
  },
};

const AdminSignIn = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [user, loadingAuthState] = useAuthState(auth);
  const [isLoading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const checkAdminPrivileges = async (user) => {
    if (!user || !user.getIdTokenResult) {  
      console.error("Invalid user object:", user);
      return false;
    }
  
    const idTokenResult = await user.getIdTokenResult();
    console.log("Admin Claim:", idTokenResult.claims.admin);
    return idTokenResult.claims.admin === true;  // Ensure it correctly checks admin role
  };
  

  const onSubmit = async (data) => {
    setLoading(true);
    setErrorMessage("");
    try {
      await signInUsingEmailPassword(data.email, data.password);
      const currentUser = auth.currentUser;

      // Verify if the user is an admin
      const isAdmin = await checkAdminPrivileges(currentUser);
      if (!isAdmin) {
        alert("You do not have admin privileges.");
        await signOut(auth);
        setLoading(false);
        return;
      }
      console.log("Admin logged in successfully");
      navigate("/admin");
    } catch (error) {
      setErrorMessage(error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    document.title = "Admin Sign In - Digital Visiting Card";
  
    if (!loadingAuthState && user) {
      checkAdminPrivileges(user).then((isAdmin) => {
        if (isAdmin) {
          navigate("/admin", { replace: true }); // Prevents repeated redirects
        } else {
          signOut(auth);
        }
      });
    }
  }, [user, loadingAuthState]); // Removed `navigate` from dependency array

  return (
    <>
      {isLoading ? (
        <ProgressIndicator type={2} />
      ) : (
        <div style={styles.background}>
          <div style={styles.container}>
            <h2 style={styles.title}>Admin Sign In</h2>
            {errorMessage && <p style={styles.errorMessage}>{errorMessage}</p>}
            <form style={styles.form} onSubmit={handleSubmit(onSubmit)} noValidate>
              <div style={styles.inputGroup}>
                <input
                  type="email"
                  placeholder="Email Address"
                  style={styles.input}
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: "Enter a valid email",
                    },
                  })}
                />
                {errors.email && <span style={styles.errorMessage}>{errors.email.message}</span>}
              </div>
              <div style={styles.inputGroup}>
                <input
                  type={passwordVisible ? "text" : "password"}
                  placeholder="Password"
                  style={styles.input}
                  {...register("password", { required: "Password is required" })}
                />
                <span style={styles.togglePassword} onClick={() => setPasswordVisible(!passwordVisible)}>
                  {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                </span>
                {errors.password && <span style={styles.errorMessage}>{errors.password.message}</span>}
              </div>
              <Link to="/forgot-password" style={styles.forgotPassword}>
                Forgot Password?
              </Link>
              <button type="submit" style={styles.button}>
                Log In as Admin
              </button>
              <p style={styles.signupLink}>
                Not an admin?{" "}
                <Link to="/signin" style={styles.signupLinkAnchor}>
                  Sign In Here
                </Link>
              </p>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminSignIn;
