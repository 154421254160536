import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { auth } from '../firebase';
import { updateUserPaymentStatus } from '../services/firebaseAuthService';
import './PaymentPage.css';

const PaymentPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  // Retrieve and decrypt user data from URL query parameter
  const queryParams = new URLSearchParams(location.search);
  const encryptedData = queryParams.get('data');
  let decryptedData = { name: '', email: '', mobile: '' };

  if (encryptedData) {
    try {
      const decoded = atob(encryptedData); // assuming base64 encoding
      // Expected format: "firstName|mobile|email"
      const parts = decoded.split('|');
      if (parts.length === 3) {
        decryptedData = { name: parts[0], mobile: parts[1], email: parts[2] };
      }
    } catch (err) {
      console.error("Error decoding encrypted data", err);
    }
  }

  // Loads Razorpay SDK
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  // Create order by calling backend API (amount fixed at 499)
  const createOrder = async () => {
    const response = await fetch('https://digicard-payment-server.onrender.com/create-order', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ amount: 499 })
    });
    return await response.json();
  };

  // Main payment flow
  const handlePayment = async () => {
    setIsProcessing(true);
    setErrorMsg('');

    const scriptLoaded = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
    if (!scriptLoaded) {
      setErrorMsg('Razorpay SDK failed to load. Please try again.');
      setIsProcessing(false);
      return;
    }

    let orderData;
    try {
      orderData = await createOrder();
    } catch (error) {
      setErrorMsg('Failed to create order. Please try again.');
      setIsProcessing(false);
      return;
    }

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      amount: orderData.amount,
      currency: orderData.currency,
      name: 'Premium Plan',
      description: 'Yearly Subscription for Premium Plan',
      order_id: orderData.id,
      prefill: {
        name: decryptedData.name || (auth.currentUser ? auth.currentUser.displayName : ''),
        email: decryptedData.email || (auth.currentUser ? auth.currentUser.email : ''),
        contact: decryptedData.mobile || (auth.currentUser ? auth.currentUser.phoneNumber : ''),
      },
      theme: {
        color: '#1e90ff'
      },
      handler: async (response) => {
        try {
          const verifyResponse = await fetch('https://digicard-payment-server.onrender.com/verify-payment', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
            }),
          });
          if (verifyResponse.status === 200) {
            if (auth.currentUser) {
              await updateUserPaymentStatus(auth.currentUser.uid, {
                paymentId: response.razorpay_payment_id,
                orderId: response.razorpay_order_id,
                signature: response.razorpay_signature,
              });
              setPaymentSuccess(true);
            } else {
              setErrorMsg('User not authenticated. Please log in and try again.');
            }
          } else {
            setErrorMsg('Payment verification failed. Please try again.');
          }
        } catch (err) {
          setErrorMsg('Payment succeeded, but updating status failed. Please contact support.');
        }
        setIsProcessing(false);
      }
    };

    const paymentObject = new window.Razorpay(options);

    paymentObject.on('payment.failed', () => {
      setErrorMsg('Payment failed. Please try again.');
      setIsProcessing(false);
    });

    paymentObject.on('modal.closed', () => {
      if (isProcessing) {
        setErrorMsg('Payment process was cancelled by the user.');
        setIsProcessing(false);
      } else {
        setErrorMsg('Payment window closed. No action was taken.');
      }
    });

    paymentObject.open();
  };

  // If payment is successful, show success view content
  if (paymentSuccess) {
    return (
      <div className="payment-success-container">
        <div className="success-card">
          <h1>Payment Successful!</h1>
          <p>Your premium subscription is now active.</p>
          <div className="premium-benefits">
            <h2>Premium Benefits</h2>
            <ul>
              <li>No ads on your digital card</li>
              <li>Customizable card colors and styles</li>
              <li>Unlimited edits to update your card anytime</li>
              <li>Direct integration with Google Maps for your address</li>
            </ul>
          </div>
          <div className="actions">
            <Link to="/">
              <button className="explore-button">Explore Premium Features</button>
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="payment-page">
      <div className="card">
        <div className="card-header">
          <button className="back-btn" onClick={() => navigate(-1)}>&larr;</button>
          <h1>Go Premium</h1>
        </div>
        <div className="card-body">
          <p className="description">
            Upgrade to Premium for an ad-free experience, customizable card colors and styles, unlimited edits, and direct Google Maps integration for your address.
          </p>
          <div className="price-section">
            <span className="price-label">Price:</span>
            <span className="price">₹499/year</span>
          </div>
          <div className="features-section">
            <h2>Premium Features</h2>
            <ul className="features">
              <li>No ads on your digital card</li>
              <li>Card color change options</li>
              <li>Multiple card styles to choose from</li>
              <li>Unlimited edits to update your card anytime</li>
              <li>Direct integration with Google Maps for your address</li>
            </ul>
          </div>
          <div className="terms-section">
            <p>
              By upgrading, you agree to our&nbsp;
              <a href="/terms" target="_blank" rel="noopener noreferrer">Terms &amp; Conditions</a>.
            </p>
          </div>
          {errorMsg && <div className="error">{errorMsg}</div>}
        </div>
        <div className="card-footer">
          <button className="pay-btn" onClick={handlePayment} disabled={!agreedToTerms || isProcessing}>
            {isProcessing ? 'Processing...' : 'Pay Now'}
          </button>
          <div className="agree-checkbox">
            <input 
              type="checkbox" 
              id="agree" 
              checked={agreedToTerms} 
              onChange={(e) => setAgreedToTerms(e.target.checked)} 
            />
            <label htmlFor="agree">
              I agree to the&nbsp;
              <a href="https://dgtldigicard.com/dgtl-digicard-premium-terms/" target="_blank" rel="noopener noreferrer">Terms &amp; Conditions</a>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
