/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import {
  updateEmail,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import { auth } from "../firebase";
import {
  getUserData,
  updateUserData,
  checkEmailExists,
} from "../services/firebaseAuthService";
import ProgressIndicator from "../components/ProgressIndicator";
import "./EditProfile.css";

const EditProfile = () => {
  const navigate = useNavigate();
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [user, loadingAuthState] = useAuthState(auth);
  const [isLoading, setLoading] = useState(false);

  // Load user data when the component mounts
  useEffect(() => {
    loadUserData();
  }, []);

  const loadUserData = async () => {
    if (loadingAuthState) return;
    if (!user) return navigate("/signin");

    await user.reload(); // Refresh the user's auth data
    const userData = await getUserData(user.uid);
    if (!userData) return navigate("/404");
    reset(userData);
    setLoading(false);
  };

  const onSubmit = async (data) => {
    if (!user) return;
    setLoading(true);

    try {

      // Check if email has changed
      if (data.email !== user.email) {
        // Prompt for re-authentication
        const password = prompt("Please enter your password to confirm email change:");
        if (!password) return setLoading(false);

        const credential = EmailAuthProvider.credential(user.email, password);
        await reauthenticateWithCredential(user, credential);

        // Check if the new email already exists in Firebase Authentication
        if (await checkEmailExists(data.email)) {
          alert("This email is already in use. Please use another email.");
          return setLoading(false);
        }

        // Update email in Firebase Authentication
        await updateEmail(user, data.email);
        await user.reload(); // Refresh the user data
      }


      // Update Firestore with the new user data, including the new email from data.email
      await updateUserData(user.uid, data);
      

      alert("Profile updated successfully.");
      navigate("/");
    } catch (error) {
      alert(`Error: ${error.message}`);
    }

    setLoading(false);
  };

  return isLoading ? (
    <ProgressIndicator type={2} />
  ) : (
    <div id="Edit-Profile">
      <form noValidate className="Form" onSubmit={handleSubmit(onSubmit)}>
        <h2 className="MT0">Edit Profile</h2>
        <InputField
          label="First Name"
          name="firstName"
          register={register}
          errors={errors}
          pattern={/^[a-zA-Z ]{2,30}$/}
        />
        <InputField
          label="Last Name"
          name="lastName"
          register={register}
          errors={errors}
          pattern={/^[a-zA-Z ]{2,30}$/}
        />
        <InputField
          label="E-mail"
          name="email"
          type="email"
          register={register}
          errors={errors}
          pattern={/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/}
        />
        <InputField
          label="Contact Number"
          name="mobile"
          type="number"
          register={register}
          errors={errors}
          pattern={/^[0-9]{10,15}$/}
        />
        <InputField
          label="Business Name"
          name="businessName"
          register={register}
          errors={errors}
          pattern={/^[a-zA-Z ]{2,30}$/}
        />
        <InputField
          label="Address"
          name="address"
          register={register}
          errors={errors}
        />
        <InputField
          label="Website"
          name="website"
          register={register}
          errors={errors}
          pattern={/^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/}
        />
        <InputField
          label="About"
          name="about"
          register={register}
          errors={errors}
        />
        <input className="Btn-Submit" type="submit" value="Update" />
        <p className="Login-Link">
          Go back to <Link to="/">Home</Link>
        </p>
      </form>
    </div>
  );
};

// Reusable input field component
const InputField = ({ label, name, type = "text", register, errors, pattern }) => (
  <div className="Field">
    <input
      type={type}
      placeholder={`${label}*`}
      {...register(name, { required: true, pattern })}
    />
    {errors[name] && <span className="Error">Invalid {label.toLowerCase()}</span>}
  </div>
);

export default EditProfile;
