import React from 'react';
import "./PageNotFound.css";
import { useNavigate } from 'react-router';
import { signOutUser } from '../services/firebaseAuthService.js';

const PageNotFound = () => {
  const navigate = useNavigate();

  const handleBackHome = async () => {
    await signOutUser();
    navigate("/");
  };

  return (
    <div id="Page-Not-Found">
      <div className="container">
        <h1>Error 404</h1>
        <h3>Page not found</h3>
        <button id="Btn-Back-Home" onClick={handleBackHome}>
          BACK TO HOME
        </button>
      </div>
    </div>
  );
};

export default PageNotFound;
