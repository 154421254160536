import React, { useEffect, useState, useMemo } from "react";
import "./DigitalCard.css";
import QRCode from "qrcode.react";
import { useNavigate } from "react-router-dom";
import {
  FaImage,
  FaUserTie,
  FaPhoneAlt,
  FaLink,
  FaEnvelope,
  FaMapMarkerAlt,
  FaShareAlt,
} from "react-icons/fa";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";

const DigitalCard = ({ userInfo, isCurrUser, setIsSettingsOpen }) => {

  const navigate = useNavigate();
  // Helper: darken/lighten a hex color by a percentage
  const shadeColor = (color, percent) => {
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);
    R = Math.min(255, parseInt((R * (100 + percent)) / 100));
    G = Math.min(255, parseInt((G * (100 + percent)) / 100));
    B = Math.min(255, parseInt((B * (100 + percent)) / 100));
    const RR = R.toString(16).padStart(2, "0");
    const GG = G.toString(16).padStart(2, "0");
    const BB = B.toString(16).padStart(2, "0");
    return `#${RR}${GG}${BB}`;
  };

  // Generate clickable website link
  const getClickableLink = (link) => {
    if (!link) return "";
    return link.startsWith("http://") || link.startsWith("https://")
      ? link
      : `http://${link}`;
  };

  // Determine variant; if none is set, default to "default"
  const cardVariant = userInfo.cardStyle || "default";
  // We'll use "modern" and "classic" inline below
  const [cardColor, setCardColor] = useState(userInfo.cardColor || "#1187ac");

  useEffect(() => {
    let title = "Digital Business Card";
    if (userInfo.firstName) {
      title = `${userInfo.firstName} ${userInfo.lastName || ""} - Digital Card`;
    }
    document.title = title;
  }, [userInfo]);

  const handleColorChange = async (e) => {
    const newColor = e.target.value;
    setCardColor(newColor);
    try {
      const userDocRef = doc(db, "users", userInfo.uid);
      await updateDoc(userDocRef, { cardColor: newColor }, { merge: true });
    } catch (error) {
      console.error("Error updating card color:", error);
    }
  };

  const generateVCardData = (userInfo) => {
    let {
      firstName = "",
      lastName = "",
      mobile = "",
      email = "",
      businessName = "",
      website = "",
      address = "",
      about = "",
    } = userInfo;
    if (mobile && (mobile.length === 10 || mobile.length === 11) && !mobile.startsWith("+")) {
      mobile = `+91${mobile}`;
    }
    return `BEGIN:VCARD
VERSION:4.0
FN:${firstName} ${lastName}
TEL;TYPE=cell,voice:${mobile}
EMAIL:${email}
ORG:${businessName}
URL:${website}
ADR;TYPE=work:;;${address};;;
NOTE:${about}
END:VCARD`;
  };

  const vcardUrl = useMemo(() => {
    const vcardData = generateVCardData(userInfo);
    const blob = new Blob([vcardData], { type: "text/vcard" });
    return URL.createObjectURL(blob);
  }, [userInfo]);

  const saveContact = async () => {
    const vcard = generateVCardData(userInfo);
    if (window.showSaveFilePicker) {
      try {
        const options = {
          suggestedName: `${userInfo.firstName}_${userInfo.lastName}.vcf`,
          types: [{ description: "vCard File", accept: { "text/vcard": [".vcf"] } }],
        };
        const fileHandle = await window.showSaveFilePicker(options);
        const writableStream = await fileHandle.createWritable();
        await writableStream.write(vcard);
        await writableStream.close();
      } catch (error) {
        console.error("Error saving file:", error);
      }
    } else {
      const blob = new Blob([vcard], { type: "text/vcard" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.download = `${userInfo.firstName}_${userInfo.lastName}.vcf`;
      link.href = url;
      link.click();
      URL.revokeObjectURL(url);
    }
  };

  const shareContact = async () => {
    const vcard = generateVCardData(userInfo);
    if (navigator.share) {
      try {
        await navigator.share({
          title: `${userInfo.firstName} ${userInfo.lastName} - Digital Card`,
          url: window.location.href,
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      try {
        await navigator.clipboard.writeText(vcard);
        alert("Contact details copied to clipboard!");
      } catch (error) {
        alert("Sharing is not supported on this browser.");
      }
    }
  };

  return (
    <div
      className="digital-card"
      style={{
        // We define a CSS variable for Section 1 background
        "--section1-bg": `linear-gradient(135deg, ${cardColor}, ${shadeColor(cardColor, -20)})`
      }}
    >

      {/* Section 1: Header (Profile, Business, QR, Actions & Color Changer) */}
      {cardVariant === "default" && (
      <div className="card-section-1">
        <div id="Action-Buttons">
              <div id="Top-Right">
                {userInfo.isPremium !== true && isCurrUser && (
                  <button
                    id="upgrade-button"
                    onClick={() => {
                      const { firstName, mobile, email } = userInfo;
                      const dataString = `${firstName}|${mobile}|${email}`;
                      const encryptedData = btoa(dataString);
                      navigate(`/payment?data=${encryptedData}`);
                    }}
                  >
                    Upgrade to Premium
                  </button>
                )}
                  <button
                    className="settings-nav-button"
                    onClick={() => setIsSettingsOpen(true)}
                  >
                    ⚙️
                  </button>
              </div> 
            </div>
        <div className="section1-header">
        
          <div className="profile-row">
            <div className="profile">
              {(!userInfo.imgUrl || userInfo.imgUrl === "")
                ? <FaImage size="80" color="#fff" />
                : <img src={userInfo.imgUrl} alt="Profile" />
              }
            </div>
            <h2 className="business-name">{userInfo.businessName}</h2>
          </div>
          <div className="qr-and-actions">
            <div className="qr-code">
            <a href={vcardUrl} download={`${userInfo.firstName}_${userInfo.lastName}.vcf`}>
                <QRCode value={generateVCardData(userInfo)} size={140} fgColor="#ffffff" bgColor="transparent" />
              </a>
            </div>
            <div className="actions-col">
              <button onClick={saveContact} className="btn">Save Contact</button>
              <button onClick={shareContact} className="btn">
                <FaShareAlt /> Share
              </button>
            </div>
          </div>
          {isCurrUser && userInfo.isPremium === true && (
            <div className="color-changer">
              <label htmlFor="cardColor">Choose Card Color:</label>
              <input type="color" id="cardColor" value={cardColor} onChange={handleColorChange} />
            </div>
          )}
        </div>
      </div>)}


      {/* Render different Section 1 based on variant */}
      {cardVariant === "modern" && (
        <div
          className="card-section-1"
          style={{
            background: "var(--section1-bg)",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            borderRadius: "8px 0 0 0"
          }}
        >
          {/* Modern variant: Top row with profile and business info */}
          <div
            className="header-top"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <div
              className="profile"
              style={{
                width: "80px",
                height: "80px",
                borderRadius: "50%",
                overflow: "hidden",
                background: "rgba(255,255,255,0.3)"
              }}
            >
              {(!userInfo.imgUrl || userInfo.imgUrl === "")
                ? <FaImage size="80" color="#fff" />
                : <img src={userInfo.imgUrl} alt="Profile" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
              }
            </div>
            <div
              className="business-info"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end"
              }}
            >
              <h2
                className="business-name"
                style={{
                  fontSize: "1.8rem",
                  margin: "0",
                  color: "#fff",
                  textShadow: "0 2px 5px rgba(0,0,0,0.6)"
                }}
              >
                {userInfo.businessName}
              </h2>
              
            </div>
          </div>
          {/* Modern variant: Bottom row with QR and vertical actions */}
          <div
            className="header-bottom"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "10px"
            }}
          >
            <div className="qr-code" style={{ textAlign: "center" }}>
              <a href={vcardUrl} download={`${userInfo.firstName}_${userInfo.lastName}.vcf`}>
                <QRCode value={generateVCardData(userInfo)} size={140} fgColor="#ffffff" bgColor="transparent" />
              </a>
            </div>
            <div
              className="actions-col"
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px"
              }}
            >
              <button
                onClick={saveContact}
                className="btn"
                style={{
                  background: "rgba(255,255,255,0.9)",
                  color: cardColor,
                  border: "none",
                  padding: "10px 15px",
                  borderRadius: "25px",
                  fontSize: "1rem",
                  cursor: "pointer",
                  transition: "transform 0.3s ease"
                }}
              >
                Save Contact
              </button>
              <button
                onClick={shareContact}
                className="btn"
                style={{
                  background: "rgba(255,255,255,0.9)",
                  color: cardColor,
                  border: "none",
                  padding: "10px 15px",
                  borderRadius: "25px",
                  fontSize: "1rem",
                  cursor: "pointer",
                  transition: "transform 0.3s ease"
                }}
              >
                <FaShareAlt style={{ marginRight: "8px" }} /> Share
              </button>
            </div>
            {isCurrUser && (
                <div
                  className="color-changer"
                  style={{ marginTop: "5px", fontSize: "0.9rem" }}
                >
                  <label htmlFor="cardColor" style={{ marginRight: "8px" }}>Color:</label>
                  <input
                    type="color"
                    id="cardColor"
                    value={cardColor}
                    onChange={handleColorChange}
                    style={{ border: "none", background: "none", cursor: "pointer" }}
                  />
                </div>
              )}
          </div>
        </div>
      )}

      {cardVariant === "classic" && (
        <div
          className="card-section-1"
          style={{
            background: "var(--section1-bg)",
            padding: "25px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            borderBottom: "2px solid #ccc"
          }}
        >
          {/* Classic variant: Separate header and action rows */}
          <div
            className="classic-header"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingLeft: "30px",
            }}
          >
            <div
              className="profile-classic"
              style={{
                width: "80px",
                height: "80px",
                borderRadius: "50%",
                overflow: "hidden",
                background: "#fff",
                border: "2px solid #ccc"
              }}
            >
              {(!userInfo.imgUrl || userInfo.imgUrl === "")
                ? <FaImage size="70" color="#ccc" />
                : <img src={userInfo.imgUrl} alt="Profile" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
              }
            </div>
            <h2
              className="business-name-classic"
              style={{
                fontSize: "1.7rem",
                margin: "0",
                color: "#333",
                fontFamily: "'Times New Roman', serif"
              }}
            >
              {userInfo.businessName}
            </h2>
          </div>
          <div
            className="classic-bottom"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "15px"
            }}
          >
            <div className="qr-code-classic" style={{ flex: "1", textAlign: "center" }}>
              <a href={vcardUrl} download={`${userInfo.firstName}_${userInfo.lastName}.vcf`}>
                <QRCode value={generateVCardData(userInfo)} size={120} fgColor="#333" bgColor="transparent" />
              </a>
            </div>
            <div
              className="actions-col-classic"
              style={{
                flex: "1",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <button
                onClick={saveContact}
                className="btn"
                style={{
                  background: "#fff",
                  color: "#333",
                  border: "2px solid #ccc",
                  padding: "8px 12px",
                  borderRadius: "20px",
                  fontSize: "0.9rem",
                  cursor: "pointer",
                  transition: "transform 0.3s ease"
                }}
              >
                Save Contact
              </button>
              <button
                onClick={shareContact}
                className="btn"
                style={{
                  background: "#fff",
                  color: "#333",
                  border: "2px solid #ccc",
                  padding: "8px 12px",
                  borderRadius: "20px",
                  fontSize: "0.9rem",
                  cursor: "pointer",
                  transition: "transform 0.3s ease"
                }}
              >
                <FaShareAlt style={{ marginRight: "8px" }} /> Share
              </button>
            </div>
          </div>
          {isCurrUser && (
            <div
              className="color-changer-classic"
              style={{ marginTop: "10px", textAlign: "center", fontSize: "0.9rem" }}
            >
              <label htmlFor="cardColor" style={{ marginRight: "8px" }}>Color:</label>
              <input
                type="color"
                id="cardColor"
                value={cardColor}
                onChange={handleColorChange}
                style={{ border: "none", background: "none", cursor: "pointer" }}
              />
            </div>
          )}
        </div>
      )}

      {/* Section 2: Details (common to all variants) */}
      <div className="card-section-2" style={{ background: "#fff", padding: "15px" }}>
        <div className="details" style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <div className="detail-item" style={{ display: "flex", alignItems: "center", padding: "5px 0",color: "#1187ac" }}>
            <FaUserTie style={{ fontSize: "1.2rem", marginRight: "10px" }} />
            <span >{userInfo.firstName} {userInfo.lastName}</span>
          </div>
          <hr style={{ borderTop: "1px solid #eee", margin: "5px 0" }} />
          <div className="detail-item" style={{ display: "flex", alignItems: "center", padding: "5px 0" }}>
            <FaEnvelope style={{ fontSize: "1.2rem", marginRight: "10px", color: "#1187ac" }} />
            <a href={`mailto:${userInfo.email}`} style={{ color: "#1187ac", textDecoration: "none" }}>{userInfo.email}</a>
          </div>
          <hr style={{ borderTop: "1px solid #eee", margin: "5px 0" }} />
          <div className="detail-item" style={{ display: "flex", alignItems: "center", padding: "5px 0" }}>
            <FaPhoneAlt style={{ fontSize: "1.2rem", marginRight: "10px", color: "#1187ac" }} />
            <a href={`tel:${userInfo.mobile}`} style={{ color: "#1187ac", textDecoration: "none" }}>{userInfo.mobile}</a>
          </div>
          <hr style={{ borderTop: "1px solid #eee", margin: "5px 0" }} />
          <div className="detail-item" style={{ display: "flex", alignItems: "center", padding: "5px 0" }}>
            <FaLink style={{ fontSize: "1.2rem", marginRight: "10px", color: "#1187ac" }} />
            <a
              href={getClickableLink(userInfo.website)}
              target="_blank"
              rel="noreferrer"
              style={{ color: "#1187ac", textDecoration: "none" }}
            >
              {userInfo.website || "No Website"}
            </a>
          </div>
          <hr style={{ borderTop: "1px solid #eee", margin: "5px 0" }} />
          <div className="detail-item" style={{ display: "flex", alignItems: "center", padding: "5px 0" }}>
            <FaMapMarkerAlt style={{ fontSize: "1.2rem", marginRight: "10px", color: "#1187ac" }} />
            {userInfo.isPremium ? (
          <a
            href={`http://maps.google.com/?q=${userInfo.address}`}
            target="_blank"
            rel="noreferrer"
            style={{ color: "#1187ac", textDecoration: "none" }}
          >
            {userInfo.address}
          </a>
          ) : (
          <span>{userInfo.address}</span>
          )}

          </div>
          <div className="about-us" style={{ padding: "10px", background: "#f2f2f2", borderRadius: "8px", marginTop: "10px" }}>
            <h4 style={{ margin: "0 0 5px", fontSize: "1rem", color: "#555" }}>About Us</h4>
            <p style={{ margin: 0, fontSize: "0.9rem", lineHeight: "1.4", color: cardColor }}>
              {userInfo.about}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalCard;
