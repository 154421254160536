import './App.css';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Home from './pages/Home';
import ForgotPassword from './pages/ForgotPassword';
import PageNotFound from './pages/PageNotFound';
import EditProfile from './pages/EditProfile';
import PaymentPage from './pages/PaymentPage';

import AffiliateRegistration from './pages/AffiliateRegistration';

// Admin-related imports
import AdminDashboard from './admin/AdminDashboard';
import AdminSignIn from './admin/AdminSignIn';
// import { AuthProvider, useAuth } from './admin/auth';
import {useEffect} from 'react'

import { initializeGoogleAnalytics } from "./googleAnalytics";



function App() {

  useEffect(() => {
    initializeGoogleAnalytics();
  }, []);

  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Public routes */}
          <Route path="/" element={<Home />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/edit" element={<EditProfile />} />
          <Route path="/:userId" element={<Home />} />
          <Route path="/404" element={<PageNotFound />} />
          <Route path="/payment" element={<PaymentPage />} />
          
          <Route path="/affiliate-registration" element={<AffiliateRegistration />} />

          {/* Admin routes wrapped in AuthProvider */}
          <Route path="/admin/login" element={<AdminSignIn />} />
          <Route path="/admin" element={<AdminDashboard />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
