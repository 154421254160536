import React, { useEffect, useState, useCallback } from "react";
import "./Home.css";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import { useNavigate, useParams } from "react-router-dom";
import { getUserData, signOutUser } from "../services/firebaseAuthService";
import ProgressIndicator from "../components/ProgressIndicator";
import DigitalCard from "../components/DigitalCard";
import { getAdBannerSettings } from "../services/adService";
import UserSettings from "../components/UserSettings";

// SwitchingAd Component moved outside Home for improved performance
const SwitchingAd = ({ adSettings, userInfo, isDesktop, isLoading ,isCurrUser}) => {
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prev) => (prev === 0 ? 1 : 0));
    }, 4000); // Switch every 4 seconds

    return () => clearInterval(interval);
  }, []);

  // Do not display ad if settings aren't loaded or if the user is premium
  if (!adSettings || userInfo?.isPremium) return null;

  const images = [
    isDesktop ? adSettings.imageDesktop : adSettings.imageMobile,
    isDesktop
      ? adSettings.imageDesktop2 || adSettings.imageDesktop
      : adSettings.imageMobile2 || adSettings.imageMobile,
  ];

  return (
    <div id="Banner-Ad">
      {adSettings.type === "manual" ? (
        <a href={ isCurrUser?adSettings.link:"/signin"} target="_blank" rel="noopener noreferrer">
          <img src={images[currentImage]} alt="Banner Ad" />
        </a>
      ) : (
        <div
          dangerouslySetInnerHTML={{
            __html: `<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3041897768324642" crossorigin="anonymous"></script>`,
          }}
        />
      )}
    </div>
  );
};

const Home = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [user, loadingAuthState] = useAuthState(auth);
  const [isCurrUser, setIsCurrUser] = useState(false);
  const [adSettings, setAdSettings] = useState(null);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  const params = useParams();
  const userIdParam = params.userId?.trim();

  const loadUserData = useCallback(async () => {
    setLoading(true);
    if (!userIdParam) {
      if (!user) {
        setLoading(false);
        return navigate("/signin");
      } else {
        const userData = await getUserData(user.uid);
        setLoading(false);
        if (!userData.customUID) {
          return navigate(`/${userData.uid}`);
        }
        return navigate(`/${userData.customUID}`);
      }
    }
    const userData = await getUserData(userIdParam);
    if (!userData) {
      setLoading(false);
      return navigate("/404");
    } else {
      setUserInfo(userData);
      setIsCurrUser(
        user &&
          (userData.customUID === userIdParam || userData.uid === userIdParam)
      );
    }
    setLoading(false);
  }, [userIdParam, user, navigate]);

  useEffect(() => {
    if (!loadingAuthState) {
      loadUserData();
    }
  }, [loadingAuthState, loadUserData]);

  useEffect(() => {
    async function fetchAdSettings() {
      const settings = await getAdBannerSettings();
      setAdSettings(settings);
    }
    fetchAdSettings();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSignoutClick = async () => {
    await signOutUser();
    localStorage.removeItem("isAdmin");
    navigate("/signin");
  };

  const handleEditProfile = () => {
    navigate("/edit");
  };

  return (
    <>
      {/* Banner Ad for free-tier users */}
      <SwitchingAd
        adSettings={adSettings}
        userInfo={userInfo}
        isDesktop={isDesktop}
        isLoading={isLoading}
        isCurrUser={isCurrUser}
      />

      {isLoading ? (
        <ProgressIndicator />
      ) : userInfo.blocked ? (
        <div id="Blocked-Message" className="blocked-container">
          <h2>Account Suspended</h2>
          <p>
            Your account has been temporarily blocked due to a policy violation
            or security reasons. If you believe this is a mistake or wish to
            resolve the issue, please contact support:
          </p>
          <p>
            📧 <a href="mailto:contact@dgtlmart.com">contact@dgtlmart.com</a>
          </p>
          <p>
            📞 <a href="tel:+919810559439">+91 9810559439</a>
          </p>
        </div>
      ) : (
        <div id="Home" className={`home-container1 ${userInfo.isPremium ? "paid" : "free"}`}>
          <div id="Home-Content">
            <DigitalCard userInfo={userInfo} isCurrUser={isCurrUser} setIsSettingsOpen={setIsSettingsOpen}/>
          </div>
        </div>
      )}

      {/* Settings Modal */}
      {isSettingsOpen && (
        <UserSettings
          currentUser={userInfo}
          onLogout={handleSignoutClick}
          onEditProfile={handleEditProfile}
          onClose={() => setIsSettingsOpen(false)}
          isPremium={userInfo.isPremium}
          isCurrUser={isCurrUser}
        />
      )}
    </>
  );
};

export default Home;
