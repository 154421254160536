import React, { useState, useCallback, useEffect } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "../utils/getCroppedImg";
import { ref, uploadString, getDownloadURL } from "firebase/storage";
import { doc , getDoc , updateDoc } from "firebase/firestore";
import { storage, db } from "../firebase";
import "./UserSettings.css";

const UserSettings = ({ currentUser, onLogout, onEditProfile, onClose, isPremium ,isCurrUser}) => {
  
  // For profile image change (crop modal)
  const [isCropModalOpen, setIsCropModalOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [preview, setPreview] = useState(null);

  // For card style changer
  const [cardStyle, setCardStyle] = useState("default");

  // Cropper callback
  const onCropComplete = useCallback((croppedArea, croppedPixels) => {
    setCroppedAreaPixels(croppedPixels);
  }, []);


  useEffect(() => {
    const fetchCardStyle = async () => {
      if (!currentUser?.uid) return;
      const userDocRef = doc(db, "users", currentUser.uid);
      const userSnap = await getDoc(userDocRef);
      if (userSnap.exists() && userSnap.data().cardStyle) {
        setCardStyle(userSnap.data().cardStyle);
      }
    };
    fetchCardStyle();
  }, [currentUser]);

  // Save selected cardStyle to Firestore
const handleCardStyleChange = async (e) => {
  const newStyle = e.target.value;
  setCardStyle(newStyle);

  if (currentUser?.uid) {
    const userDocRef = doc(db, "users", currentUser.uid);
    await updateDoc(userDocRef, { cardStyle: newStyle });
  }
};

  useEffect(() => {
    if (imageSrc && croppedAreaPixels) {
      (async () => {
        try {
          const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
          setPreview(croppedImage);
        } catch (error) {
          console.error("Error generating preview:", error);
        }
      })();
    }
  }, [imageSrc, croppedAreaPixels]);

  const handleImageChange = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const imageDataUrl = await readFile(file);
      setImageSrc(imageDataUrl);
      setIsCropModalOpen(true);
    }
  };

  const readFile = (file) =>
    new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result));
      reader.readAsDataURL(file);
    });

  const handleSaveImage = useCallback(async () => {
    try {
      const croppedImageDataUrl = await getCroppedImg(imageSrc, croppedAreaPixels);
      const storageRef = ref(storage, `images/${currentUser.uid}.png`);
      const metadata = { contentType: "image/png" };
      await uploadString(storageRef, croppedImageDataUrl, "data_url", metadata);
      const downloadURL = await getDownloadURL(storageRef);
      const userDocRef = doc(db, "users", currentUser.uid);
      await updateDoc(userDocRef, { imgUrl: downloadURL }, { merge: true });
      setIsCropModalOpen(false);
      setImageSrc(null);
      setPreview(null);
      window.location.reload();
    } catch (error) {
      console.error("Error saving image:", error);
    }
  }, [imageSrc, croppedAreaPixels, currentUser]);

  // Save card style to localStorage
  useEffect(() => {
    localStorage.setItem("cardStyle", cardStyle);
  }, [cardStyle]);

  if (!isCurrUser) {
    return (
      <div className="user-settings-sidebar">
        <header className="settings-header">
          <h2>User Settings</h2>
          <button className="modal-close-button" onClick={onClose}>✖</button>
        </header>
        <main className="settings-main">
          <p className="login-message">
            Please log in or sign up to create a new account or access your Digital Card for making changes.
          </p>
        </main>
        <footer className="settings-footer">
          <button className="login-button" onClick={() => window.location.href = "/signin"}>
            Login
          </button>
        </footer>
      </div>
    );
  }

  return (
    <>
      {/* Overlay for closing the sidebar */}
      <div className="settings-overlay" onClick={onClose}></div>

      {/* Right Sidebar */}
      <div className="user-settings-sidebar">
        <header className="settings-header">
          <h2>User Settings</h2>
          <button className="modal-close-button" onClick={onClose}>✖</button>
        </header>

        <main className="settings-main">
          <div className="profile-section">
            <div className="profile-image-container">
              {currentUser.imgUrl ? (
                <img src={currentUser.imgUrl} alt="Profile" className="profile-image" />
              ) : (
                <div className="profile-image placeholder">No Image</div>
              )}
            </div>
          </div>

          <div className="options-container">
            {/* Edit Details Button */}
            <div className="tooltip-container">
            <button
              className="settings-option"
              onClick={onEditProfile}
              disabled={!isPremium}
            >
              Edit Details
            </button>
            {!isPremium && <span className="tooltip">Buy Premium to unlock this feature</span>}
            </div>

            {/* Change Profile Pic Button */}
            <button className="settings-option" onClick={() => setIsCropModalOpen(true)}>
              Change Profile Pic
            </button>

            {/* Card Style Changer */}
            <div className="tooltip-container">
            <div className="settings-option style-changer">
            <span>Card Style</span>
            <select value={cardStyle} onChange={handleCardStyleChange} disabled={!isPremium}>
            <option value="default">Default</option>
            <option value="modern">Modern</option>
            <option value="classic">Classic</option>
            </select>
            </div>
            {!isPremium && <span className="tooltip">Buy Premium to unlock this feature</span>}
          </div>
          </div>
        </main>

        <footer className="settings-footer">
          <button className="logout-button" onClick={onLogout}>
            Logout
          </button>
        </footer>
      </div>

      {/* Crop Modal Popup for Profile Pic */}
      {isCropModalOpen && (
        <div className="crop-modal-overlay">
          <div className="crop-modal-content">
            <div className="crop-modal-header">
              <h2>Change Profile Picture</h2>
              <button className="modal-close-button" onClick={() => setIsCropModalOpen(false)}>
                ✖
              </button>
            </div>
            <div className="crop-modal-body">
              <div className="upload-label">
                Select New Image:
                <input type="file" accept="image/*" onChange={handleImageChange} />
              </div>
              {imageSrc && (
                <>
                  <div className="crop-container">
                    <Cropper
                      image={imageSrc}
                      crop={crop}
                      zoom={zoom}
                      aspect={1}
                      onCropChange={setCrop}
                      onZoomChange={setZoom}
                      onCropComplete={onCropComplete}
                    />
                  </div>
                  <div className="zoom-control">
                    <input
                      type="range"
                      min={1}
                      max={3}
                      step={0.1}
                      value={zoom}
                      onChange={(e) => setZoom(e.target.value)}
                    />
                  </div>
                  <div className="preview-container">
                    <h3>Preview</h3>
                    {preview ? (
                      <div className="profile-image">
                        <img src={preview} alt="Cropped Preview" />
                      </div>
                    ) : (
                      <p>Generating preview...</p>
                    )}
                  </div>
                  <button className="save-image-button" onClick={handleSaveImage}>
                    Save Image
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserSettings;
